<template>
  <commonTitle v-bind:datas="datas"></commonTitle>
  <div class="about ">
    <div class="clearfix pab30">
      <div class="w1200 b_fff pab30">
        <!-- <p class="font_28 mgt40">公司简介</p> -->
        <!-- <div class="b_border"></div> -->
        <div class="pad30 clearfix min_border">
          <div class=" font_16 c_333 lh30 info_text">
			<div class="info_center">
			  <p style="text-align: left; font-size: 22px;">
				  再创佳绩！DLN北美(200MWH+)储能项目今日正式交付上线运行
			  </p>
			  <p style="text-align: left; font-size: 15px; color: #999999;">
				2023-01-21
			  </p>
			</div>
            <img
              src="../../assets/images/news/newsdetail/HEZUO-1.jpg"
              class="info_img_center" style="width: 80%;height: 80%;"
              alt="成都忆能科技有限公司"
            />
            <div class="info_center">
              <p class="min_font">
                &nbsp;&nbsp;&nbsp;&nbsp;21日，由epcenergy和忆能共同承接的DLN北美储能项目完成全部验收测试，并正式交付客户使用。该项目由忆能提供方案和技术并采用安全可靠的磷酸铁锂电池，为客户提供安全、易用、稳定的完整储能系统，并得到客户的一致好评。 
              </p>
			  <p class="min_font">
			    &nbsp;&nbsp;&nbsp;&nbsp;
			  </p>
              
              <p class="min_font"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import commonTitle from '@/components/common/commonTitle.vue'
	export default {
		components: {
			commonTitle,
		},
		methods: {
			
		},
		data() {
			return {
				datas:["news"],
			};
		},
		mounted() {
			
		},
	};
</script>
<style lang="scss">
@import "@/assets/css/about.scss";


</style>